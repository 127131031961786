

body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --font-montserrat: Montserrat;
  --b-24: Roboto;
  --tagline: Sora;

  /* font sizes */
  --tagline-size: 16px;
  --font-size-xl: 20px;
  --font-size-31xl: 50px;
  --font-size-11xl: 30px;
  --font-size-21xl: 40px;
  --b-24-size: 24px;
  --font-size-lgi: 19px;
  --font-size-3xl: 22px;
  --font-size-lg: 18px;
  --font-size-17xl: 36px;
  --font-size-10xl: 29px;

  /* Colors */
  --neutral-bg: #fafafa;
  --effects-white-80: #202636;
  --color-gray-100: rgba(255, 255, 255, 0.8);
  --neutral-white: #fff;
  --color: #003269;
  --title-color: #000;
  --color-dimgray: #666;
  --color-cornflowerblue: #0071c1;
  --color-royalblue: #0756c7;

  /* Gaps */
  --gap-base: 16px;
  --gap-mini: 15px;
  --gap-mid: 17px;
  --gap-6xl: 25px;
  --gap-4xl: 23px;
  --gap-5xl: 24px;
  --gap-13xl: 32px;
  --gap-xl: 20px;
  --gap-32xl: 51px;
  --gap-23xl: 42px;
  --gap-2xl: 21px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-9xs: 4px;
  --padding-13xl: 32px;
  --padding-6xs: 7px;
  --padding-2xs: 11px;
  --padding-9xl: 28px;
  --padding-3xl: 22px;
  --padding-7xl: 26px;
  --padding-10xl: 29px;
  --padding-5xs-5: 7.5px;
  --padding-base: 16px;
  --padding-6xl: 25px;
  --padding-11xl: 30px;
  --padding-lg: 18px;
  --padding-5xl: 24px;
  --padding-33xl: 52px;
  --padding-34xl: 53px;
  --padding-lgi: 19px;
  --padding-12xl: 31px;
  --padding-22xl: 41px;
  --padding-4xl: 23px;
  --padding-11xs: 2px;
  --padding-25xl-6: 44.6px;
  --padding-50xl: 69px;
  --padding-26xl: 45px;
  --padding-54xl: 73px;

  /* Border radiuses */
  --br-21xl: 40px;
  --br-5xl: 24px;
  --br-5xs: 8px;
  --br-141xl: 160px;
  --br-11xl: 30px;
}