.frame-container {
  position: relative;
}

.carousel-container {
  position: relative;
  z-index: 1;
  /* Mettez le z-index à une valeur plus élevée que les éléments que vous voulez mettre en arrière-plan */
}

.carousel-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* Pour être en arrière-plan */
}


.accueil {
  display: inline-block;
  min-width: 75px;
}

.accueil,
.notre-mission {
  position: relative;
  font-weight: 500;
  z-index: 1;
  left: -8rem;
}

.notre-missionn {
  position: relative;
  font-weight: 500;
  z-index: 1;
  left: -8rem;
}

.accueil-parent {

  width: 709px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 0 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
  gap: var(--gap-xl);
  z-index: 3;
}

.librer-le-pouvoir,
.carousel-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* Assure que le carousel est en arrière-plan */
}

.transformer-lnergie-solaire {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-family: inherit;
  z-index: 3;
}

.librer-le-pouvoir {
  line-height: 84px;
  text-transform: capitalize;
  font-weight: 700;
}

.transformer-lnergie-solaire {
  line-height: 38px;
  font-weight: 600;
}

.button-child {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-11xl);
  background-color: var(--neutral-white);
}

.request-a-quote {
  position: relative;
  font-size: var(--tagline-size);
  font-family: var(--font-montserrat);
  color: #1e56a1;
  text-align: center;
  z-index: 1;
}

.button {
  cursor: pointer;
  border: 0;
  padding: 21.4px 39px 18.6px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  z-index: 3;
}

.librer-le-pouvoir-du-soleil-a-parent,
.transformer-lnergie-solaire-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.transformer-lnergie-solaire-parent {
  width: 610px;
  gap: 9px;
  font-size: var(--font-size-10xl);
  color: #dadada;
}

.librer-le-pouvoir-du-soleil-a-parent {
  width: 700px;
  gap: var(--gap-mini);
}

.frame-wrapper {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  /* padding: 0 124px 0 var(--padding-xl); */
  box-sizing: border-box;
  max-width: 100%;
  font-size: 70px;
  margin-left: 15%;
}

.logo-blanc300x-1 {
  position: absolute;
  top: 138px;
  left: 185px;
  width: 258px;
  height: 88.5px;
  object-fit: contain;
  z-index: 1;
}

.background-icon,
.frame-child {
  position: absolute;
  width: 100%;
  height: 100%;
}

.background-icon {
  top: 0;
  left: 0;
  object-fit: contain;
  z-index: 2;
}

.frame-child {
  top: 66.7px;
  left: 66.7px;
  border-radius: 50%;
  background-color: var(--neutral-white);
  border: 0 solid var(--neutral-white);
  box-sizing: border-box;
  transform: rotate(180deg);
  transform-origin: 0 0;
}

.hero-slider {
  width: 100%;
  height: 100%;
}

.hero-slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider .single-slider {
  height: 600px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.slider .single-slider .text {
  margin-top: 120px;
}

.slider.index2 .single-slider .text {
  margin-top: 150px;
}

.slider .single-slider h1 {
  color: #2C2D3F;
  font-size: 38px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 42px;
}

.slider .single-slider h1 span {
  color: #1a76d1;
}

.slider .single-slider p {
  color: #2C2D3F;
  margin-top: 27px;
  font-weight: 400;
}

.slider .single-slider .button {
  margin-top: 30px;
}

.slider .single-slider .btn {
  color: #fff;
  background: #1a76d1;
  font-weight: 500;
  display: inline-block;
  margin: 0;
  margin-right: 10px;
}

.slider .single-slider .btn:last-child {
  margin: 0;
}

.slider .single-slider .btn.primary {
  background: #2C2D3F;
  color: #fff;
}

.slider .single-slider .btn.primary:before {
  background: #1A76D1;
}

.slider .owl-carousel .owl-nav {
  margin: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -25px;
}

.slider .owl-carousel .owl-nav div {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  background: #1A76D1;
  color: #fff;
  font-size: 26px;
  position: absolute;
  margin: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  padding: 0;
  border-radius: 50%;
}

.slider .owl-carousel .owl-nav div:hover {
  background: #2C2D3F;
  color: #fff;
}

.slider .owl-carousel .owl-controls .owl-nav .owl-prev {
  left: 20px;
}

.slider .owl-carousel .owl-controls .owl-nav .owl-next {
  right: 20px;
}

/* Slider Animation */
.owl-item.active .single-slider h1 {
  animation: fadeInUp 1s both 0.6s;
}

.owl-item.active .single-slider p {
  animation: fadeInUp 1s both 1s;
}

.owl-item.active .single-slider .button {
  animation: fadeInDown 1s both 1.5s;
}

.union-icon {
  position: absolute;
  top: 24.4px;
  left: 23.5px;
  width: 19.5px;
  height: 17.3px;
  object-fit: contain;
  z-index: 1;
}

.ellipse-parent {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 511px;
  left: 81px;
  width: 66.7px;
  height: 66.7px;
  z-index: 3;
}

.logo-blanc300x-1-parent {
  width: 1191.7px;
  height: 1146.3px;
  position: absolute;
  margin: 0 !important;
  top: -96px;
  left: -54px;
}

.frame-container {
  /* width: 1226px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-54xl) 0 355.3px;
  box-sizing: border-box;
  position: relative;
  gap: 270px;
  max-width: 100%;
}

.notre-proposition {
  position: relative;
  font-weight: 500;
  z-index: 1;
  left: -8rem;
}

.notre-proposition-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-54xl) 0 0;
}

/* Styles pour les liens "Blog" et "Master" */
.blog,
.master {
  margin-left: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

/* Style lorsque le lien est survolé */
.blog:hover,
.master:hover {
  text-decoration: underline;
}

.se-connecter {
  position: relative;
  font-weight: 500;
  z-index: 1;
}

.se-connecter-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0 0;
  position: relative;

}

.ic-arrow-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.icon {
  height: 32px;
  width: 32px;
  border-radius: var(--br-21xl);
  background-color: var(--neutral-white);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs);
  box-sizing: border-box;
  z-index: 1;
}

.frame-item {
  width: 66.7px;
  height: 66.7px;
  position: relative;
  object-fit: cover;
  z-index: 1;
}

.star-pointed-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 345px 0 0;
}

.frame-parent1,
.star-pointed {
  flex-direction: row;
  justify-content: flex-start;
}

.star-pointed {
  display: flex;
  align-items: flex-start;
  gap: 1px;
}

.frame-parent1 {
  gap: var(--gap-xl);
}

.frame-div,
.frame-group,
.frame-parent1 {
  display: flex;
  align-items: flex-start;
}

.frame-div {
  flex-direction: column;
  justify-content: flex-start;
  padding: 70px 0 0;
}

.frame-group {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--neutral-white);
  font-family: var(--font-montserrat);
}

.background-child {
  width: 680px;
  height: 370px;
  position: relative;
  border-radius: var(--br-11xl);
  background-color: var(--color);
  display: none;
  max-width: 100%;
}

.icon1 {
  height: 130px;
  width: 130px;
  position: relative;
  z-index: 1;
}

.icon-wrapper {
  width: 470px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 0 var(--padding-11xs);
  box-sizing: border-box;
  max-width: 100%;
}

.fournir-lespace-et {
  width: 470px;
  position: relative;
  line-height: 32px;
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
  z-index: 1;
}

.background,
.background-item {
  border-radius: var(--br-11xl);
  max-width: 100%;
}

.background {
  flex: 1;
  background-color: var(--color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-50xl) var(--padding-3xl) var(--padding-50xl) var(--padding-xl);
  box-sizing: border-box;
  gap: 38px;
  min-width: 510px;
}

.background-item {
  width: 680px;
  height: 370px;
  position: relative;
  background-color: var(--color-cornflowerblue);
  display: none;
}

.icon2 {
  height: 130px;
  width: 130px;
  position: relative;
  z-index: 1;
}

.icon-container {
  width: 502px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
}

.rduire-significativement-leur {
  width: 502px;
  height: 60px;
  position: relative;
  line-height: 32px;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
  z-index: 1;
}

.background-parent,
.background1,
.frame-wrapper1 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.background1 {
  flex: 1;
  border-radius: var(--br-11xl);
  background-color: var(--color-cornflowerblue);
  flex-direction: column;
  align-items: center;
  padding: 58px var(--padding-xl) 80px;
  box-sizing: border-box;
  gap: var(--gap-23xl);
}

.background-parent,
.frame-wrapper1 {
  flex-direction: row;
  align-items: flex-start;
}

.background-parent {
  flex: 1;
  flex-wrap: wrap;
  gap: 41px;
}

.frame-wrapper1 {
  width: 1405px;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
  text-align: center;
}

.ellipse-div,
.frame-inner {
  position: absolute;
  border-radius: 50%;
}

.frame-inner {
  top: 0;
  left: 67.79px;
  /* background-color: var(--color-cornflowerblue); */
  background-image: url('../../public//media/landingpage/Ellipse 28.png');
  width: 110.7px;
  height: 110.7px;
  transform: rotate(37.8deg);
  transform-origin: 0 0;
  z-index: 2;
}

.ellipse-div {
  height: 104.2px;
  width: 104.2px;
  margin: 0 !important;
  bottom: -28.6px;
  left: -24.8px;
  background-color: var(--neutral-white);
  border: 0 solid var(--color-cornflowerblue);
  box-sizing: border-box;
}

.save-money-1-icon {
  height: 54.6px;
  width: 54.6px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 3;
}

.ellipse-container {
  position: absolute;
  top: 46px;
  left: 49.6px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.ellipse-group {
  height: 155.3px;
  flex: 1;
  position: relative;
}

.frame-wrapper3,
.prise-en-charge {
  flex-shrink: 0;
  debug_commit: 1de1738;
}

.frame-wrapper3 {
  width: 155.3px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 155.29999999999927px;
}

.prise-en-charge {
  align-self: stretch;
  height: 59.6px;
  position: relative;
  line-height: 32px;
  font-weight: 600;
  display: inline-block;
}

.frame-parent5,
.prise-en-charge-complte-de-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.prise-en-charge-complte-de-wrapper {
  flex: 1;
  flex-direction: column;
  padding: 46.6px 0 0;
  box-sizing: border-box;
  min-width: 274px;
  margin-left: -7.3px;
}

.frame-parent5 {
  position: absolute;
  top: 298.3px;
  left: 0;
  width: 570px;
  flex-direction: row;
  row-gap: 20px;
}

.nos-services {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.input-selector {
  width: 86.3px;
  height: 2px;
  position: relative;
  background-color: var(--color);
}

.nos-services-parent {
  width: 648.9px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
  max-width: 100%;
}

.notre-proposition-unique {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-31xl);
  line-height: 80px;
  text-transform: capitalize;
  font-weight: 700;
  font-family: inherit;
  color: var(--title-color);
}

.data-aggregator {
  position: absolute;
  top: 0;
  left: 20.8px;
  width: 811.2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25.5px;
  max-width: 100%;
  font-size: var(--tagline-size);
  color: var(--color);
}

.frame-child1,
.frame-child2 {
  position: absolute;
  border-radius: 50%;
}

.frame-child1 {
  top: 0;
  left: 67.79px;
  /* background-color: var(--color-cornflowerblue); */
  background-image: url('../../public//media/landingpage/Ellipse 28.png');
  width: 110.7px;
  height: 110.7px;
  transform: rotate(37.8deg);
  transform-origin: 0 0;
  z-index: 3;
}

.frame-child2 {
  height: 104.2px;
  width: 104.2px;
  margin: 0 !important;
  bottom: -21.7px;
  left: -23px;
  background-color: var(--neutral-white);
  border: 0 solid var(--color-cornflowerblue);
  box-sizing: border-box;
}

.solar-panels-1-icon {
  height: 63.5px;
  width: 63.5px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 4;
}

.ellipse-parent2 {
  position: absolute;
  top: 44px;
  left: 48px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.ellipse-parent1 {
  height: 155.3px;
  flex: 1;
  position: relative;
}

.espace-ddi-dans,
.frame-wrapper4 {
  flex-shrink: 0;
  debug_commit: 1de1738;
}

.frame-wrapper4 {
  width: 155.3px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 155.29999999999927px;
}

.espace-ddi-dans {
  align-self: stretch;
  height: 59.6px;
  position: relative;
  line-height: 32px;
  font-weight: 600;
  display: inline-block;
}

.espace-ddi-dans-des-zones-wrapper,
.frame-parent6 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.espace-ddi-dans-des-zones-wrapper {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-25xl-6) 0 0;
  box-sizing: border-box;
  min-width: 274px;
  margin-left: -7.5px;
}

.frame-parent6 {
  position: absolute;
  top: 150.3px;
  left: 0;
  width: 569.5px;
  flex-direction: row;
  row-gap: 20px;
}

.frame-child3,
.frame-child4 {
  position: absolute;
  border-radius: 50%;
}

.frame-child3 {
  top: 0;
  left: 67.79px;
  /* background-color: var(--color-cornflowerblue); */
  background-image: url('../../public//media/landingpage/Ellipse 28.png');
  width: 110.7px;
  height: 110.7px;
  transform: rotate(37.8deg);
  transform-origin: 0 0;
  z-index: 1;
}

.frame-child4 {
  height: 104.2px;
  width: 104.2px;
  margin: 0 !important;
  top: -24px;
  left: -34px;
  background-color: var(--neutral-white);
  border: 0 solid var(--color-cornflowerblue);
  box-sizing: border-box;
}

.frame-icon {
  height: 57px;
  width: 52px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.ellipse-parent4 {
  position: absolute;
  top: 49px;
  left: 59px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.ellipse-parent3 {
  height: 155.3px;
  flex: 1;
  position: relative;
}

.frame-wrapper5 {
  width: 155.3px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 155.29999999999927px;
  flex-shrink: 0;
  debug_commit: 1de1738;
}

.suivi-performant-via {
  align-self: stretch;
  position: relative;
  line-height: 32px;
  font-weight: 600;
  flex-shrink: 0;
  debug_commit: 1de1738;
}

.frame-parent7,
.suivi-performant-via-plateform-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.suivi-performant-via-plateform-wrapper {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-25xl-6) 0 0;
  box-sizing: border-box;
  min-width: 274px;
  margin-left: -7.3px;
}

.frame-parent7 {
  position: absolute;
  top: 447.3px;
  left: 0;
  width: 570px;
  flex-direction: row;
  row-gap: 20px;
}

.frame-parent4 {
  width: 832px;
  height: 602.6px;
  position: relative;
  flex-shrink: 0;
  debug_commit: 1de1738;
  max-width: 116%;
}

.frame-wrapper2 {
  width: 719px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 27.7px 0 0;
  box-sizing: border-box;
  min-width: 719px;
  max-width: 100%;
}

.mask-group-icon {
  width: 684px;
  position: relative;
  max-height: 100%;
  object-fit: contain;
  flex-shrink: 0;
  debug_commit: 1de1738;
  max-width: 100%;
  z-index: 1;
}

.frame-parent3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
  max-width: 100%;
  color: var(--color-dimgray);
}

.avantages-et-proposition {
  flex: 1;
  position: relative;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  display: inline-block;
  max-width: 100%;
}

.shape-collection {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-11xs);
  box-sizing: border-box;
  max-width: 100%;
}

.size-scale-child {
  height: 2px;
  width: 87px;
  position: relative;
  background-color: var(--color);
  display: inline-table;
}

.size-scale {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-4xl) 0 var(--padding-xl);
}

.pourquoi-choisir-gso {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-31xl);
  line-height: 80px;
  text-transform: capitalize;
  font-weight: 700;
  font-family: inherit;
  color: var(--title-color);
}

.alignment-system {
  width: 1002px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  max-width: 100%;
}

.background-inner,
.layout-manager {
  box-sizing: border-box;
  max-width: 100%;
}

.layout-manager {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-4xl) 0 var(--padding-xl);
}

.background-inner {
  width: 410px;
  height: 399.7px;
  position: relative;
  border-radius: var(--br-11xl);
  background-color: var(--neutral-white);
  border: 5px solid var(--color-royalblue);
  display: none;
}

.rectangle-div {
  height: 120px;
  width: 70px;
  position: relative;
  border-radius: var(--br-21xl);
  background-color: var(--color-cornflowerblue);
  display: none;
}

.b {
  flex: 1;
  position: relative;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  z-index: 1;
}

.button-bank-inner,
.rectangle-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.rectangle-parent {
  width: 70px;
  border-radius: var(--br-21xl);
  background-color: var(--color-cornflowerblue);
  justify-content: flex-start;
  padding: var(--padding-22xl) var(--padding-6xl) var(--padding-22xl) var(--padding-10xl);
  box-sizing: border-box;
  z-index: 2;
}

.button-bank-inner {
  align-self: stretch;
  justify-content: flex-end;
}

.optimisez-vos-dpenses {
  position: relative;
  font-size: var(--tagline-size);
  line-height: 28px;
  font-family: var(--font-montserrat);
  color: var(--color-dimgray);
  text-align: left;
  display: inline-block;
  max-width: 100%;
  z-index: 1;
  margin-left: 2rem;
}

.button-bank {
  width: 365px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xl);
  max-width: 100%;
}

.appointment-1-icon {
  height: 60px;
  width: 60px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.background2,
.slider-controller,
.star-shape {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.star-shape {
  flex-direction: row;
  padding: 0 var(--padding-2xs);
}

.background2,
.slider-controller {
  box-sizing: border-box;
  max-width: 100%;
}

.background2 {
  flex: 1;
  border-radius: var(--br-11xl);
  background-color: var(--neutral-white);
  border: 5px solid var(--color-royalblue);
  flex-direction: column;
  padding: var(--padding-lgi) var(--padding-6xs) var(--padding-12xl) var(--padding-9xl);
  gap: var(--gap-32xl);
}

.slider-controller {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
}

.frame-child5 {
  height: 120px;
  width: 405px;
  position: relative;
  border-radius: var(--br-11xl);
  background-color: var(--neutral-bg);
  display: none;
  max-width: 100%;
}

.rduction-des-cots {
  width: 271px;
  position: relative;
  line-height: 36px;
  display: inline-block;
  white-space: pre-wrap;
  flex-shrink: 0;
  z-index: 2;
}

.image-holder,
.rectangle-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.rectangle-group {
  width: 405px;
  height: 120px;
  border-radius: var(--br-11xl);
  background-color: var(--neutral-bg);
  flex-direction: row;
  padding: var(--padding-5xl) var(--padding-34xl);
  box-sizing: border-box;
  white-space: nowrap;
  z-index: 1;
  margin-top: -374.7px;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--title-color);
  font-family: var(--font-montserrat);
}

.image-holder {
  flex: 1;
  flex-direction: column;
  min-width: 322px;
  min-height: 400px;
}

.background-child1 {
  width: 410px;
  height: 399.7px;
  position: relative;
  border-radius: var(--br-11xl);
  background-color: var(--neutral-white);
  border: 5px solid var(--color-royalblue);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}

.frame-child6 {
  height: 120px;
  width: 70px;
  position: relative;
  border-radius: var(--br-21xl);
  background-color: var(--color-cornflowerblue);
  display: none;
}

.audio-component {
  flex: 1;
  position: relative;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  z-index: 1;
}

.raster-image-inner,
.rectangle-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.rectangle-container {
  width: 70px;
  border-radius: var(--br-21xl);
  background-color: var(--color-cornflowerblue);
  justify-content: flex-start;
  padding: var(--padding-22xl) var(--padding-3xl) var(--padding-22xl) var(--padding-7xl);
  box-sizing: border-box;
  z-index: 2;
}

.raster-image-inner {
  align-self: stretch;
  justify-content: flex-end;
}

.participez-la {
  width: 330px;
  position: relative;
  font-size: var(--tagline-size);
  line-height: 28px;
  font-family: var(--font-montserrat);
  color: var(--color-dimgray);
  text-align: left;
  display: inline-block;
  max-width: 100%;
  z-index: 1;
}

.raster-image {
  width: 365px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xl);
  max-width: 100%;
}

.house-2-1 {
  height: 71px;
  width: 71px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.background-wrapper,
.background3,
.input-field {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.input-field {
  flex-direction: row;
  padding: 0 var(--padding-2xs);
}

.background-wrapper,
.background3 {
  box-sizing: border-box;
  max-width: 100%;
}

.background3 {
  flex: 1;
  border-radius: var(--br-11xl);
  background-color: var(--neutral-white);
  border: 5px solid var(--color-royalblue);
  flex-direction: column;
  padding: var(--padding-lgi) var(--padding-6xs) var(--padding-10xl) var(--padding-9xl);
  gap: var(--gap-23xl);
}

.background-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
}

.dropdown-menu-child {
  height: 120px;
  width: 405px;
  position: relative;
  border-radius: var(--br-11xl);
  background-color: var(--neutral-bg);
  display: none;
  max-width: 100%;
}

.engagement-actif,
.pour-la-dcarbonation {
  margin: 0;
}

.engagement-actif-pour-container {
  flex: 1;
  position: relative;
  line-height: 36px;
  z-index: 3;
}

.dropdown-menu,
.frame-parent8 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.dropdown-menu {
  width: 405px;
  position: relative;
  border-radius: var(--br-11xl);
  background-color: var(--neutral-bg);
  flex-direction: row;
  padding: var(--padding-5xl) var(--padding-33xl) var(--padding-5xl) var(--padding-34xl);
  box-sizing: border-box;
  z-index: 1;
  margin-top: -374.7px;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--title-color);
  font-family: var(--font-montserrat);
}

.frame-parent8 {
  align-self: stretch;
  flex-direction: column;
  min-height: 400px;
}

.background-child2,
.frame-wrapper6 {
  box-sizing: border-box;
  max-width: 100%;
}

.frame-wrapper6 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 5px var(--padding-11xl) 0;
  min-width: 326px;
}

.background-child2 {
  width: 410px;
  height: 399.7px;
  position: relative;
  border-radius: var(--br-11xl);
  background-color: var(--neutral-white);
  border: 5px solid var(--color-royalblue);
  display: none;
}

.frame-child7 {
  height: 120px;
  width: 70px;
  position: relative;
  border-radius: var(--br-21xl);
  background-color: var(--color-cornflowerblue);
  display: none;
}

.b1 {
  position: relative;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  display: inline-block;
  min-width: 22px;
  z-index: 1;
}

.frame-wrapper7,
.group-div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.group-div {
  border-radius: var(--br-21xl);
  background-color: var(--color-cornflowerblue);
  justify-content: flex-start;
  padding: var(--padding-22xl) var(--padding-3xl) var(--padding-22xl) var(--padding-7xl);
  z-index: 2;
}

.frame-wrapper7 {
  align-self: stretch;
  justify-content: flex-end;
}

.assurez-votre-autonomie {
  width: 330px;
  position: relative;
  font-size: var(--tagline-size);
  line-height: 28px;
  font-family: var(--font-montserrat);
  color: var(--color-dimgray);
  text-align: left;
  display: inline-block;
  max-width: 100%;
  z-index: 1;
}

.frame-parent9 {
  width: 365px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xl);
  max-width: 100%;
}

.hired-1-1 {
  height: 60px;
  width: 60px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.background-container,
.background4,
.hired-1-1-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.hired-1-1-wrapper {
  flex-direction: row;
  padding: 0 var(--padding-2xs);
}

.background-container,
.background4 {
  box-sizing: border-box;
  max-width: 100%;
}

.background4 {
  flex: 1;
  border-radius: var(--br-11xl);
  background-color: var(--neutral-white);
  border: 5px solid var(--color-royalblue);
  flex-direction: column;
  padding: var(--padding-lgi) var(--padding-6xs) var(--padding-12xl) var(--padding-9xl);
  gap: var(--gap-32xl);
}

.background-container {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
}

.frame-child8 {
  height: 120px;
  width: 405px;
  position: relative;
  border-radius: var(--br-11xl);
  background-color: var(--neutral-bg);
  display: none;
  max-width: 100%;
}

.accs-lindpendance {
  flex: 1;
  position: relative;
  line-height: 36px;
  z-index: 3;
}

.image-holder1,
.rectangle-parent1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.rectangle-parent1 {
  width: 405px;
  border-radius: var(--br-11xl);
  background-color: var(--neutral-bg);
  flex-direction: row;
  padding: var(--padding-5xl) var(--padding-33xl) var(--padding-5xl) var(--padding-34xl);
  box-sizing: border-box;
  z-index: 1;
  margin-top: -374.7px;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--title-color);
  font-family: var(--font-montserrat);
}

.image-holder1 {
  flex: 1;
  flex-direction: column;
  min-width: 322px;
  min-height: 400px;
}

.image-holder-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  gap: 50px;
  max-width: 100%;
  font-size: var(--font-size-11xl);
  color: var(--neutral-white);
  font-family: var(--tagline);
}

.function-tree,
.function-tree-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.function-tree {
  flex: 1;
  flex-direction: column;
  gap: 48px;
}

.function-tree-wrapper {
  width: 1401px;
  flex-direction: row;
  padding: 0 3px 39.3px;
  box-sizing: border-box;
  text-align: center;
  font-size: var(--tagline-size);
  color: var(--color);
}

.image-icon {
  width: 684px;
  position: relative;
  border-radius: var(--br-5xl);
  max-height: 100%;
  object-fit: contain;
  max-width: 100%;
}

.engagement {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.chart-component {
  width: 87px;
  height: 2px;
  position: relative;
  background-color: var(--color);
}

.gallery {
  width: 654px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
  max-width: 100%;
}

.avec-gso {
  margin: 0;
  /* width: 560px; */
  position: relative;
  font-size: 60px;
  line-height: 80px;
  text-transform: capitalize;
  font-weight: 700;
  font-family: var(--font-montserrat);
  color: var(--title-color);
  display: inline-block;
  max-width: 100%;
}

.et-en-ligne,
.soutenu-par-des {
  margin: 0;
}

.soutenu-par-des-container {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-11xl);
  line-height: 56px;
  font-weight: 400;
  font-family: inherit;
  color: var(--color-dimgray);
}

.carousel {
  width: 683px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24.5px;
  max-width: 100%;
}

.icon-cluster,
.image-array {
  position: absolute;
  border-radius: 50%;
}

.image-array {
  top: 25px;
  left: 25px;
  background-color: var(--neutral-white);
  border: 0 solid var(--color-cornflowerblue);
  box-sizing: border-box;
  width: 104.2px;
  height: 104.2px;
}

.icon-cluster {
  top: 0;
  left: 67.79px;
  /* background-color: var(--color-cornflowerblue); */
  background-image: url('../../public//media/landingpage/Ellipse 28.png');
  width: 110.7px;
  height: 110.7px;
  transform: rotate(37.8deg);
  transform-origin: 0 0;
  z-index: 1;
}

.save-time-1-icon {
  position: absolute;
  top: 50px;
  left: 50px;
  width: 55px;
  height: 55px;
  overflow: hidden;
  z-index: 2;
}

.icon-cluster-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.amliorez-votre-gestion,
.image-array-parent {
  position: relative;
  flex-shrink: 0;
  debug_commit: 1de1738;
}

.image-array-parent {
  height: 155.3px;
  width: 155.3px;
}

.amliorez-votre-gestion {
  align-self: stretch;
  height: 91.6px;
  line-height: 32px;
  font-weight: 600;
  display: inline-block;
}

.frame-parent10,
.oval-gallery,
.shape-collection1 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.oval-gallery {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: 34.6px 0 0;
  box-sizing: border-box;
  min-width: 352px;
  margin-left: -7.3px;
}

.frame-parent10,
.shape-collection1 {
  flex-direction: row;
}

.frame-parent10 {
  flex: 1;
  justify-content: flex-start;
  row-gap: 20px;
}

.shape-collection1 {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 var(--padding-xl) 0 0;
  box-sizing: border-box;
  font-size: var(--font-size-xl);
  color: var(--color-dimgray);
  font-family: var(--font-montserrat);
}

.popover-window,
.stepper,
.tabs,
.tool-tip {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.stepper {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-end;
  gap: 52px;
}

.popover-window,
.tabs,
.tool-tip {
  align-items: flex-start;
}

.tabs {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-3xl) 0 0;
  box-sizing: border-box;
  min-width: 461px;
}

.popover-window,
.tool-tip {
  flex-direction: row;
}

.tool-tip {
  flex: 1;
  gap: var(--gap-xl);
}

.popover-window {
  align-self: stretch;
  padding: 0 0 144.1px var(--padding-lg);
  box-sizing: border-box;
  font-size: var(--tagline-size);
  color: var(--color);
  font-family: var(--tagline);
}

.slaheddine-el-guetari {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 48px;
  font-weight: 600;
  font-family: inherit;
}

.slaheddineelguetarigmailcom {
  position: relative;
  font-size: var(--font-size-3xl);
  line-height: 40px;
  display: inline-block;
  white-space: nowrap;
  max-width: 100%;
}

.info,
.text {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.text {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.info {
  flex-direction: row;
  align-items: center;
  font-size: var(--font-size-17xl);
}

.phone-icon {
  width: 32px;
  height: 32px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.mdishovel {
  cursor: pointer;
  border: 0;
  padding: var(--padding-base);
  background-color: var(--neutral-white);
  height: 64px;
  width: 64px;
  border-radius: var(--br-141xl);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}

.decagon-dwell {
  position: relative;
  line-height: 40px;
  white-space: nowrap;
}

.info1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}

.map-pin-icon {
  width: 32px;
  height: 32px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.mdishovel1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-base);
  background-color: var(--neutral-white);
  height: 64px;
  width: 64px;
  border-radius: var(--br-141xl);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}

.rue {
  position: relative;
  line-height: 40px;
  display: inline-block;
  min-width: 95px;
}

.info2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  font-family: var(--b-24);
}

.contact-info,
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  max-width: 100%;
}

.wrapper {
  align-self: stretch;
  align-items: flex-start;
  padding: 0;
  gap: var(--gap-13xl);
}

.contact-info {
  width: 488px;
  backdrop-filter: blur(72px);
  border-radius: var(--br-5xl);
  background-color: rgba(0, 19, 55, 0.6);
  align-items: center;
  padding: 34.5px 48px;
  min-width: 488px;
  z-index: 1;
}

.image-icon1 {
  height: 635.4px;
  width: 864.7px;
  position: absolute;
  margin: 0 !important;
  top: -82.4px;
  left: -581.6px;
  border-radius: var(--br-5xl);
  object-fit: cover;
}

.color-cluster-child {
  width: 800.3px;
  height: 625.1px;
  position: relative;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-5xl);
  background-color: var(--color-gray-100);
  display: none;
  max-width: 100%;
}

.contactez-nous {
  margin: 0;
  width: 480.9px;
  position: relative;
  font-size: inherit;
  line-height: 80px;
  text-transform: capitalize;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
  z-index: 2;
}

.vous-souhaitez-nous {
  align-self: stretch;
  position: relative;
  line-height: 40px;
  z-index: 2;
}

.input-box {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: var(--color-gray-100);
  height: 76px;
  flex: 1;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-lg) var(--padding-11xl);
  box-sizing: border-box;
  font-family: var(--font-montserrat);
  font-size: var(--b-24-size);
  color: var(--effects-white-80);
  min-width: 181px;
  z-index: 2;
}

.email {
  position: relative;
  line-height: 40px;
  display: inline-block;
  min-width: 69px;
}

.input-box1,
.shape-shadowing {
  flex-direction: row;
  max-width: 100%;
}

.input-box1 {
  width: 392.5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-5xs);
  background-color: var(--color-gray-100);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 17px var(--padding-11xl);
  box-sizing: border-box;
  z-index: 2;
}

.shape-shadowing {
  gap: var(--gap-5xl);
}

.input-box2,
.shape-shadowing,
.symbol-swatch,
.vous-souhaitez-nous-contacter-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.input-box2 {
  border: 0;
  background-color: var(--color-gray-100);
  height: 171.7px;
  width: auto;
  outline: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-5xs);
  flex-direction: row;
  padding: var(--padding-6xl) var(--padding-13xl);
  box-sizing: border-box;
  font-family: var(--font-montserrat);
  font-size: var(--b-24-size);
  color: var(--effects-white-80);
  z-index: 2;
}

.symbol-swatch,
.vous-souhaitez-nous-contacter-parent {
  flex-direction: column;
  max-width: 100%;
}

.symbol-swatch {
  gap: var(--gap-mid);
}

.vous-souhaitez-nous-contacter-parent {
  padding: 0 0 23.6px;
  box-sizing: border-box;
  gap: 28px;
  font-size: var(--b-24-size);
  color: var(--effects-white-80);
}

.envoyer {
  position: relative;
  font-size: var(--b-24-size);
  letter-spacing: 0.02em;
  line-height: 32px;
  font-weight: 600;
  font-family: var(--font-montserrat);
  color: var(--neutral-white);
  text-align: left;
  display: inline-block;
  min-width: 103px;
}

.icon-infographic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0 0;
}

.arrow-right-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.arrow {
  height: 40px;
  width: 40px;
  border-radius: var(--br-21xl);
  background-color: var(--neutral-white);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  box-sizing: border-box;
}

.arrow,
.button1,
.color-cluster {
  justify-content: flex-start;
}

.button1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs-5) var(--padding-base) var(--padding-5xs-5) var(--padding-13xl);
  background-color: var(--color);
  border-radius: var(--br-21xl);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-5xl);
  z-index: 2;
}

.color-cluster {
  flex: 1;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-5xl);
  background-color: var(--color-gray-100);
  flex-direction: column;
  padding: 41.9px 52.4px 44.5px;
  box-sizing: border-box;
  gap: 3.7px;
  z-index: 1;
}

.color-cluster,
.gradient-grid,
.opacity-options {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.opacity-options {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
}

.gradient-grid {
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 0 3.5px;
  box-sizing: border-box;
  min-width: 520px;
  font-size: var(--font-size-31xl);
  color: var(--title-color);
}

.arcs-assembly,
.contact-info-parent,
.frame-parent2 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.contact-info-parent {
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  gap: 93.6px;
}

.arcs-assembly,
.frame-parent2 {
  align-items: flex-start;
}

.arcs-assembly {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-9xl) 0 var(--padding-3xl);
  box-sizing: border-box;
  font-size: var(--b-24-size);
}

.frame-parent2 {
  width: 1432px;
  flex-direction: column;
  gap: 145px;
}

.frame-parent,
.frame-section {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}

.frame-section {
  width: 1815.4px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--neutral-white);
  font-family: var(--font-montserrat);
}

.frame-parent {
  width: 1903.7px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 0 0;
  gap: 49.7px;
  flex-shrink: 0;
  debug_commit: 1de1738;
}

.pexels-gustavo-fring-4254158-1-icon {
  width: 100%;
  height: 1031px;
  position: absolute;
  margin: 0 !important;
  top: -150px;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.notre-mission1 {
  margin: 0 !important;
  width: 1000px;
  position: absolute;
  top: 850px;
  right: 458px;
  font-size: inherit;
  line-height: 80px;
  text-transform: capitalize;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  z-index: 3;
}

.background-child3 {
  width: 1924px;
  height: 370px;
  position: relative;
  background-color: var(--color);
  display: none;
  max-width: 100%;
}

.logo-blanc300x-11 {
  height: 88.5px;
  width: 258px;
  position: relative;
  object-fit: cover;
  z-index: 1;
}

.logo-blanc300x-1-wrapper {
  width: 1250px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
}

.prt-faire {
  width: 1250px;
  position: relative;
  line-height: 45px;
  text-transform: capitalize;
  font-weight: 500;
  white-space: pre-wrap;
  display: inline-block;
  max-width: 100%;
  z-index: 1;
}

.icon3,
.input-array {
  position: relative;
  z-index: 2;
}

.icon3 {
  height: 40px;
  width: 40px;
}

.input-array {
  display: inline-block;
  min-width: 119px;
  white-space: nowrap;
  padding: 2%;
}

.condition-branch,
.octagon-orchard-plus {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.condition-branch {
  display: flex;
  align-items: center;
  padding: var(--padding-2xs) 0 0;
}

.octagon-orchard-plus {
  flex-direction: row;
  gap: var(--gap-mid);
}

.icon4 {
  height: 40px;
  width: 40px;
  position: relative;
  z-index: 2;
}

.slaheddineelguetarigmailcom1 {
  position: relative;
  line-height: 28px;
  white-space: nowrap;
  z-index: 2;
}

.slaheddineelguetarigmailcom-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-6xs) 0 0;
}

.heptagon-hive-plus,
.octagon-orchard-plus1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.octagon-orchard-plus1 {
  justify-content: flex-start;
  gap: var(--gap-mid);
}

.heptagon-hive-plus {
  justify-content: center;
  gap: 62px;
}

.background5,
.triangle-trio-plus {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}

.triangle-trio-plus {
  width: 1250px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 0 1px;
  text-align: left;
  font-size: var(--tagline-size);
  color: var(--neutral-bg);
}

.background5 {
  margin-left: -2px;
  /* margin-bottom: 1290px; */
  align-self: stretch;
  background-color: var(--color);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-13xl) var(--padding-xl) 55px;
  gap: 32.3px;
  flex-shrink: 0;
  debug_commit: 1de1738;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--neutral-white);
  font-family: var(--font-montserrat);
}

.ic-arrow-icon1 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.icon5 {
  height: 32px;
  width: 32px;
  border-radius: var(--br-21xl);
  background-color: var(--neutral-white);
  flex-direction: row;
  justify-content: flex-start;
  padding: var(--padding-9xs);
  box-sizing: border-box;
  flex-shrink: 0;
  debug_commit: 1de1738;
}

.function-call,
.home-5,
.icon5 {
  display: flex;
  align-items: flex-start;
}

.function-call {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl) 0 1100px;
}

.home-5 {
  width: 100%;
  height: 5252px;
  position: relative;
  background-color: var(--neutral-bg);

  flex-direction: column;
  justify-content: flex-start;
  gap: 158px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  font-size: var(--font-size-31xl);
  color: var(--title-color);
  font-family: var(--font-montserrat);
}

@media screen and (max-width: 1725px) {
  .frame-group {
    flex-wrap: wrap;
  }

  .frame-wrapper2,
  .mask-group-icon {
    flex: 1;
  }

  .frame-parent3 {
    flex-wrap: wrap;
  }

  .home-5 {
    height: auto;
  }
}

@media screen and (max-width: 1325px) {
  .frame-container {
    gap: 135px;
    padding-top: 47px;
    padding-bottom: 231px;
    box-sizing: border-box;
  }

  .image-icon {
    flex: 1;
  }

  .tool-tip {
    flex-wrap: wrap;
  }

  .contact-info {
    flex: 1;
  }

  .contact-info-parent {
    flex-wrap: wrap;
  }

  .frame-parent2 {
    gap: 72px;
  }

  .function-call {
    padding-left: 550px;
    box-sizing: border-box;
  }

  .home-5 {
    gap: 79px;
  }
}

@media screen and (max-width: 900px) {
  .librer-le-pouvoir {
    font-size: 56px;
    line-height: 67px;
  }

  .frame-wrapper {
    padding-right: 62px;
    box-sizing: border-box;
  }

  .frame-container {
    gap: 67px;
    padding-top: var(--padding-12xl);
    padding-bottom: 150px;
    box-sizing: border-box;
  }

  .frame-div {
    padding-top: var(--padding-26xl);
    box-sizing: border-box;
  }

  .background {
    gap: 19px;
    min-width: 100%;
  }

  .background1 {
    gap: var(--gap-2xl);
  }

  .background-parent {
    gap: var(--gap-xl);
  }

  .frame-wrapper3 {
    flex: 1;
  }

  .frame-parent5 {
    flex-wrap: wrap;
  }

  .notre-proposition-unique {
    font-size: var(--font-size-21xl);
    line-height: 64px;
  }

  .frame-wrapper4 {
    flex: 1;
  }

  .frame-parent6 {
    flex-wrap: wrap;
  }

  .frame-wrapper5 {
    flex: 1;
  }

  .frame-parent7 {
    flex-wrap: wrap;
  }

  .frame-parent4 {
    height: auto;
    min-height: 602.6000000000004;
  }

  .frame-wrapper2 {
    min-width: 100%;
  }

  .pourquoi-choisir-gso {
    font-size: var(--font-size-21xl);
    line-height: 64px;
  }

  .audio-component,
  .b,
  .b1 {
    font-size: var(--b-24-size);
  }

  .image-holder-parent {
    gap: var(--gap-6xl);
  }

  .function-tree {
    gap: var(--gap-5xl);
  }

  .avec-gso {
    font-size: 48px;
    line-height: 64px;
  }

  .soutenu-par-des-container {
    font-size: var(--b-24-size);
    line-height: 45px;
  }

  .oval-gallery {
    min-width: 100%;
    margin-left: 0;
  }

  .frame-parent10 {
    flex-wrap: wrap;
  }

  .stepper {
    gap: 26px;
  }

  .tabs {
    min-width: 100%;
  }

  .popover-window {
    padding-bottom: 94px;
    box-sizing: border-box;
  }

  .slaheddine-el-guetari {
    font-size: var(--font-size-10xl);
    line-height: 38px;
  }

  .info2 {
    flex-wrap: wrap;
  }

  .contact-info {
    gap: var(--gap-5xl);
    padding-left: var(--padding-5xl);
    padding-right: var(--padding-5xl);
    box-sizing: border-box;
    min-width: 100%;
  }

  .contactez-nous {
    font-size: var(--font-size-21xl);
    line-height: 64px;
  }

  .shape-shadowing {
    flex-wrap: wrap;
  }

  .color-cluster {
    padding: 27px var(--padding-7xl) var(--padding-10xl);
    box-sizing: border-box;
  }

  .gradient-grid {
    min-width: 100%;
  }

  .contact-info-parent {
    gap: 47px;
  }

  .frame-parent2 {
    gap: 36px;
  }

  .frame-parent {
    gap: var(--gap-6xl);
  }

  .notre-mission1 {
    font-size: var(--font-size-21xl);
    line-height: 64px;
  }

  .heptagon-hive-plus {
    flex-wrap: wrap;
    gap: 31px;
  }

  .background5 {
    gap: var(--gap-base);
  }

  .function-call {
    padding-left: 275px;
    box-sizing: border-box;
  }

  .home-5 {
    gap: 39px;
  }
}

@media screen and (max-width: 450px) {

  .accueil,
  .notre-mission {
    font-size: var(--tagline-size);
  }

  .librer-le-pouvoir {
    font-size: 42px;
    line-height: 50px;
  }

  .transformer-lnergie-solaire {
    font-size: 23px;
    line-height: 30px;
  }

  .frame-wrapper {
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }

  .frame-container {
    gap: 34px;
  }

  .notre-proposition,
  .se-connecter {
    font-size: var(--tagline-size);

  }

  .star-pointed-inner {
    padding-top: 224px;
    box-sizing: border-box;
  }

  .fournir-lespace-et {
    font-size: var(--tagline-size);
    line-height: 26px;
  }

  .background {
    padding-top: var(--padding-26xl);
    padding-bottom: var(--padding-26xl);
    box-sizing: border-box;
  }

  .rduire-significativement-leur {
    font-size: var(--tagline-size);
    line-height: 26px;
  }

  .background1 {
    padding-top: 38px;
    padding-bottom: var(--padding-33xl);
    box-sizing: border-box;
  }

  .prise-en-charge {
    font-size: var(--tagline-size);
    line-height: 26px;
  }

  .prise-en-charge-complte-de-wrapper {
    margin-left: 0;
  }

  .notre-proposition-unique {
    font-size: var(--font-size-11xl);
    line-height: 48px;
  }

  .espace-ddi-dans {
    font-size: var(--tagline-size);
    line-height: 26px;
  }

  .espace-ddi-dans-des-zones-wrapper {
    margin-left: 0;
  }

  .suivi-performant-via {
    font-size: var(--tagline-size);
    line-height: 26px;
  }

  .suivi-performant-via-plateform-wrapper {
    margin-left: 0;
  }

  .pourquoi-choisir-gso {
    font-size: var(--font-size-11xl);
    line-height: 48px;
  }

  .b {
    font-size: var(--font-size-lg);
  }

  .background2 {
    gap: var(--gap-6xl);
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }

  .rectangle-group {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }

  .audio-component {
    font-size: var(--font-size-lg);
  }

  .background3 {
    gap: var(--gap-2xl);
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }

  .engagement-actif-pour-container {
    font-size: var(--font-size-lg);
    line-height: 29px;
  }

  .dropdown-menu {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }

  .b1 {
    font-size: var(--font-size-lg);
  }

  .background4 {
    gap: var(--gap-6xl);
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }

  .accs-lindpendance {
    font-size: var(--font-size-lg);
    line-height: 29px;
  }

  .rectangle-parent1 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }

  .avec-gso {
    font-size: var(--font-size-17xl);
    line-height: 48px;
  }

  .soutenu-par-des-container {
    font-size: var(--font-size-lg);
    line-height: 34px;
  }

  .amliorez-votre-gestion {
    font-size: var(--tagline-size);
    line-height: 26px;
  }

  .popover-window {
    padding-bottom: 61px;
    box-sizing: border-box;
  }

  .slaheddine-el-guetari {
    font-size: var(--font-size-3xl);
    line-height: 29px;
  }

  .slaheddineelguetarigmailcom {
    font-size: var(--font-size-lg);
    line-height: 32px;
  }

  .info {
    gap: var(--gap-base);
  }

  .decagon-dwell {
    font-size: var(--font-size-lgi);
    line-height: 32px;
  }

  .info1 {
    flex-wrap: wrap;
    gap: var(--gap-base);
  }

  .rue {
    font-size: var(--font-size-lgi);
    line-height: 32px;
  }

  .info2,
  .wrapper {
    gap: var(--gap-base);
  }

  .contact-info {
    padding-top: var(--padding-3xl);
    padding-bottom: var(--padding-3xl);
    box-sizing: border-box;
  }

  .contactez-nous {
    font-size: var(--font-size-11xl);
    line-height: 48px;
  }

  .email,
  .envoyer,
  .vous-souhaitez-nous {
    font-size: var(--font-size-lgi);
    line-height: 32px;
  }

  .envoyer {
    line-height: 26px;
  }

  .button1 {
    flex-wrap: wrap;
  }

  .contact-info-parent {
    gap: var(--gap-4xl);
  }

  .frame-parent2 {
    gap: 18px;
  }

  .notre-mission1 {
    font-size: var(--font-size-11xl);
    line-height: 48px;
  }

  .prt-faire {
    font-size: var(--tagline-size);
    line-height: 36px;
  }

  .heptagon-hive-plus {
    gap: var(--gap-mini);
  }

  .background5 {
    padding-top: 21px;
    padding-bottom: 36px;
    box-sizing: border-box;
  }

  .function-call {
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.landing-page {
  width: 100%;
}

/* Header */
.header {
  /* background-image: url('header-background.jpg'); */
  background-size: cover;
  background-position: center;
  text-align: center;
  color: white;
}

.navbar .logo {
  font-size: 24px;
  font-weight: bold;
}

.hero h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.cta-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

/* Mission Section */
.mission {
  padding: 50px;
  text-align: center;
}

.mission h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.mission-cards {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.mission .card {
  flex: 1 1 45%;
  padding: 20px;
  background-color: #003269;
  ;
  color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 680px;
  height: 370px;
  justify-content: center;
  border-radius: 30px;


}

.title {
  font-family: Montserrat;
  font-size: 50px !important;
  font-weight: 700;
  line-height: 80px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.card-content {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

/* Proposition Section */
.proposition {
  padding: 50px;
  background-color: #f9f9f9;
  text-align: center;
}

.proposition h2 {
  text-align: left;
}

.proposition .content {
  /* display: flex;
  align-items: center;
  gap: 20px; */
  /* flex-wrap: wrap;
  justify-content: space-between; */
  display: flex;
    align-items: flex-end;
    gap: 20px;
    flex-direction: row;
    flex-wrap: nowrap;
}

.proposition-service {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  letter-spacing: 0.1em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Color, #003269);


}

.proposition .details {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: center;
  align-items: flex-start;
}

.proposition img {
  max-width: 50%;
  height: auto;
  border-radius: 8px;
}

/* Why Choose Section */
.why-choose {
  padding: 50px;
  text-align: center;
}

.why-choose h2 {
  margin-bottom: 20px;
}

.why-choose .cards {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.cards-title {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  background: #FAFAFA;
  border-radius: 20px 0px 0px 20px;
  width: 100%;

}

.cards-description {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #666666;


}

.one-icon {
  width: 70px;
  height: 100px;
  top: 2628px;
  left: 611px;
  gap: 0px;
  /* border-radius: 40px 0px 0px 0px; */
  opacity: 0px;

}

.card-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
}

.why-choose .card {
  flex: 1 1 30%;
  padding: 20px;
  border: 3px solid #007bff;
  border-radius: 30px;
}

/* Avec GSO Section */
.avec-gso {
  padding: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.avec-gso img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 20px;
  width: 50%;
}

/* Contact Section */
.contact {
  padding: 50px;
  background-color: #f8f9fa;
  text-align: center;
}

.contact h2 {
  margin-bottom: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 400px;
  margin: 0 auto;
}

.contact-form input,
.contact-form textarea,
.contact-form button {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
}

.contact-form button {
  background-color: #007bff;
  color: white;
  border: none;
}

/* Footer */
.footer {
  background-color: #003269;
  color: white;
  text-align: center;
  padding: 20px;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  align-items: center;



}

/* Responsive Design */
@media (max-width: 768px) {
  .proposition, .mission, .why-choose, .avec-gso, .contact  {
    padding: 30px;
  }
  .hero h1 {
    font-size: 1.5rem;
  }

  .mission .card,
  .why-choose .card,
  .proposition .details,
  .proposition img {
    flex: 1 1 100%;
  }
  .proposition img {
    max-width: 80%;
  }

  .proposition .content,
  .why-choose .cards {
    flex-direction: column;
    align-items: center;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  position: relative;
}

.hero {
  position: relative;
}

/* Styling for carousel images */
.content-style {
  width: 100%;
  height: 50rem;
  object-fit: cover;
}
.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
/* Top bar styling for logo and navbar */
.top-bar {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem; */
}

/* Logo styling */
.logo img {
  max-width: 150px;
}

/* Navbar styling */
.navbar ul {
  display: flex;
  gap: 20px;
  list-style: none;
}

.navbar a {
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  font-size: 1.2rem;
}

.navbar a:hover {
  text-decoration: underline;
}

.icon-img {
  max-width: 20% !important;
}
.menu-icon {
  display: none;
  cursor: pointer;
}
/* Overlay content centered on the carousel */
.overlay-content {
  position: absolute;
  top: 70%;
  left: 30%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: left;
  /* Aligns content to the left */
  z-index: 1;
  max-width: 65%;
  padding-inline: 5%;
}

.overlay-content h1 {
  font-size: 4rem;
  font-weight: bold;
  margin: 10px 0;
}

.cta-button {
  display: flex;
  padding: 10px 20px;
  background-color: #FFFFFF;
  color: #1E56A1;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 2rem;
}

.slider-title {
  font-family: Montserrat, sans-serif;
  font-size: 70px;
  font-weight: 700;
  line-height: 84px;
  color: #FFFFFF;
}

.slider-text {
  font-family: Montserrat, sans-serif;
  font-size: 29px;
  font-weight: 600;
  line-height: 38px;
  color: #DADADA;
  margin-top: -0.5rem;
  /* Adjust this to control space between title and text */
  margin-bottom: 1rem;
}

/* Responsive styling for mobile */
@media (max-width: 768px) {
  .p-footer{
  font-size: 16px !important;
  }
  .content-style {
    height: 30rem;
  }
  .time-icon{
    width: 30% !important;
  }

  .overlay-content h1 {
    font-size: 1.8rem;
  }
  .slider-title {
    font-size: 1.8rem; 
    line-height: 1.2;
    
  }
  
  .slider-text {
    font-size: 1rem;
    line-height: 10px;
  }
  
  .overlay-content {
    padding-inline: 10%;
  }
  .title{
    font-size: 3rem !important;
    line-height: normal;
    padding-block: 1rem
  }
  .cta-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
  .menu-icon {
    display: block;
  }

  .navbar {
    position: absolute;
    top: 60px;
    right: 0;
    background: #fff;
    width: 200px;
  /* transform: translateX(100%);
    transition: transform 0.3s ease-in-out;  */

  }

  /* When the menu is open, show the navbar */
  .navbar.open {
    transform: translateX(0);
    display: flex;
    justify-content: center;
  }
  .navbar.close {
    display: none;
  }
.navbar a {
  color: #003269;
}

  .navbar ul {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .p-content, .optimisez-vos-dpenses {
    font-size:  16px !important;
    
  }

  .avec-gso img {
    max-width: 100%;
    width: 80%;
  }
 

  .gso-container {
    /* flex-direction: column; */
    flex-direction: column-reverse;
    text-align: center;
    display: flex !important;
  }
  .image-icon {
    width: 100%; /* Full width on small screens */
  }

  .nav-link {
    text-decoration: none; 
    color: #333; /* Dark gray color */
    font-size: 1.2rem; /* Slightly larger font */
    font-weight: 500; /* Medium weight for better readability */
    position: relative; /* Needed for underline effect */
    padding: 0.5rem 1rem; /* Adds padding for a button-like look */
    transition: color 0.3s ease; /* Smooth color transition on hover */
  }
  
  /* Hover color change */

  /* .navbar ul li:hover{
    background-color: #0071c11a;
    width: 100%;
    padding-inline: 1rem;
    padding-block: 0.25rem;
    border-radius: 4rem;
  } */
  .navbar ul li {
    padding-inline: 1rem;
    padding-block: 0.25rem;
    border-radius: 4rem;
    transition: background-color 0.3s ease;
  }
  
  .navbar ul li:hover {
    background-color: #0071c11a;
  }
  
  .navbar ul li a {
    display: block;
    width: 100%;
    text-align: center;
  }

}

.gso-container {
  display: flex;
  align-items: center;
}

.avec-gso-container {
  display: flex;
  gap: 20;
  justify-content: flex-start;
  align-items: center;
}

.p-content {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #666666;
  /* width: 683px;
  height: 280px;
  top: 3375px;
  left: 1009px;
  gap: 0px;
  opacity: 0px; */

}

.contact-section {
  display: flex;
  justify-content: center;
}

.contact-container {

  border-radius: 24px 0px 0px 0px;
  background: #FFFFFFCC;
  box-shadow: 0px 4px 4px 0px #00000040;
  margin-left: -15rem;
  width: 40%;
  margin-top: 5rem;
  margin-bottom: -3rem;
}

.form-input {
  display: flex;
}

.user-info {
  /* width: Fixed (488px)px;
  height: Fixed (357px)px;
  top: 4367px;
  left: 282px; */
  /* padding: 64px 48px 64px 48px;
  gap: 48px;
  border-radius: 24px 0px 0px 0px;
  background: #00133799;
  backdrop-filter: blur(72px) */


}

.user-info {
  position: absolute;

  left: 13.5%;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  padding: 20px;
  border-radius: 8px;
  /* width: 35%; */
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20%;
}

.user-info .info,
.user-info .info1,
.user-info .info2 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-info h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
}

.user-info div {
  font-size: 2rem;
}

.p-footer {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 45px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.footer-container {
  display: flex;
  justify-content: center;

}
@media (max-width: 1024px) {
  .proposition-service {
    font-size: 1.8rem; /* Adjust service title size */
  }

  .details {
    width: 100%;
    margin-bottom: 20px;
  }

  .mask-group-icon {
    width: 100%; /* Full width for images on smaller screens */
    margin-top: 20px;
  }
}

/* Medium screens (e.g., small phones) */
@media (max-width: 768px) {
  .proposition-service {
    font-size: 1.6rem;
  }

  .details {
    width: 100%;
    padding: 10px;
  }

  .details p {
    font-size: 1rem;
    margin-bottom: -1rem;
  }

  .mask-group-icon {
    width: 100%; /* Ensure images take full width */
    margin-top: 20px;
  }
  .proposition .content {
    gap:0
  }
}

/* Extra small screens (e.g., very small phones) */
@media (max-width: 480px) {
  .proposition-service {
    font-size: 1.4rem;
  }

  .content {
    flex-direction: column;
    align-items: center;
  
  }

  .details {
    /* width: 100%;
    text-align: center;
    padding: 10px; */
    text-align: left;
    width: 100%;
  }

  .details p {
    font-size: 1.25rem;
    margin: 6px 0;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .mask-group-icon {
    width: 100%;
    margin-top: 20px;
  }
}

/* @media (max-width: 768px) {
  .contact-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 100%;
  }

  .contact-container {
    width: 80%;
  }

  .contact h2 {
    font-size: 1.5rem;
  }

  .optimisez-vos-dpenses {
    font-size: 0.9rem;
  }

  .user-info {
    position: relative;
    margin: 20px auto;
    padding: 15px;
    font-size: 0.9rem;
    max-width: 100%;
  }

  .user-info h2 {
    font-size: 1.4rem;
  }

  .user-info div {
    font-size: 0.85rem;
  }

  .contact-form input,
  .contact-form textarea,
  .contact-form button {
    font-size: 0.9rem;
  }
  .text-container{
    display: flex;
    align-items: center;
}
.contact-img{

}
} */
.contact-img{
  width: 50%;
}

@media (max-width: 768px) {
  .contact-section {
    flex-direction: column;
    align-items: center;
  }

  /* Image, formulaire et user-info prennent toute la largeur et sont empilés */
  .contact-section img,
  .contact-container,
  .user-info {
    width: 100%;
    margin: 10px 0;
  }
.phone-icon, .map-pin-icon{
  margin: 0 !important;
}
  .contact h2 {
    font-size: 1.5rem;
  }

  .optimisez-vos-dpenses {
    font-size: 0.9rem;
  }

  .user-info {
    position: static;
    width: 100%;
    margin: 0 auto;
    padding: 15px;
  }

  .user-info h2 {
    font-size: 2rem;
  }

  .user-info div {
    font-size: 1.5rem;
  }

  .contact-form input,
  .contact-form textarea,
  .contact-form button {
    font-size: 0.9rem;
  }
}